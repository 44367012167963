.ant-form-item-required::before {
  position: absolute;
  top: 10px;
  right: -2px;
}
.ant-form-item-label label {
  font-weight: 600;
}


/* TODO: Verify what is still needed in this file after rainbow was added */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Ant Design Error Input */
.ant-message-notice-content {
  padding: 0;
  background: transparent;
  border-radius: 0;
  box-shadow: 0;
}

.ant-message-custom-content.ant-message-success {
  background: #02e289;
  color: #ffffff;
  display: inline-block;
  padding: 10px 32px 10px 32px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-message-custom-content.ant-message-error {
  background: #ff1875;
  color: #ffffff;
  display: inline-block;
  padding: 10px 32px 10px 32px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-message-custom-content.ant-message-info {
  background: #3498db;
  color: #ffffff;
  display: inline-block;
  padding: 10px 32px 10px 32px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-message-custom-content.ant-message-warning {
  background: #f1c40f;
  color: #ffffff;
  display: inline-block;
  padding: 10px 32px 10px 32px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-message-custom-content i {
  display: none;
}

.ant-cascader-menu {
  height: auto;
}

img {
  max-width: 100%;
}

.ant-cascader-menu-item-active {
  background:inherit !important;
  color: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}